import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private publicHttpOptions: Object = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) {}

  setToken(token) {
    localStorage.setItem('token', token);
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  removeToken() {
    localStorage.removeItem('token');
  }

  get(url): Observable<any> {
    return this.http.get(`${environment.api_url}${url}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getToken()}`
      })
    });
  }

  post(url, data): Observable<any> {
    return this.http.post(`${environment.api_url}${url}`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getToken()}`
      })
    });
  }

  publicPost(url, data): Observable<any> {
    return this.http.post(`${environment.api_url}${url}`, data, this.publicHttpOptions);
  }

  put(url, data): Observable<any> {
    return this.http.put(`${environment.api_url}${url}`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getToken()}`
      })
    });
  }

  delete(url): Observable<any> {
    return this.http.delete(`${environment.api_url}${url}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getToken()}`
      })
    });
  }
}
