import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from './http.service';
import decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user: any = {
    "email": null,
    "name": null,
    "role": null
  }

  constructor(private router: Router, private httpService: HttpService) { }

  authenticate(user, password) {
    return this.httpService.publicPost('public/auth/login', {
        "username": user,
        "password": password
    });
  }

  login(token) {
    this.setLoggedUser(token);
    this.router.navigate(['admin']);
  }

  logout() {
    this.httpService.removeToken();
    this.clearUser();
    this.router.navigate(['login']);
  }

  getUsers() {
    return this.httpService.get('private/users');
  }

  getToken() {
    return this.httpService.getToken();
  }

  setLoggedUser(token) {
    this.httpService.setToken(token);
    const decodedToken = decode(token);
    this.user = {
      email: decodedToken.email,
      name: decodedToken.name,
      role: decodedToken.role
    };
    return this.user;
  }

  getLoggedUser() {
    return this.user;
  }

  clearUser() {
    this.user = {
      "email": null,
      "name": null,
      "role": null
    };
  }
  
}
