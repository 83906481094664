import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from './user.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  public jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(public userService: UserService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const token = this.userService.getToken();
    if (!route.data.route && (!token || this.jwtHelper.isTokenExpired(token))) {
      this.router.navigate(['login']);
      return false;
    } else if (token && !this.jwtHelper.isTokenExpired(token) && route.data.route == 'login') {
      this.userService.setLoggedUser(token);
      this.router.navigate(['admin']);
      return false;
    } else if (route.data.route !== 'login') {
      this.userService.setLoggedUser(token);
    }
    return true;
  }
}
